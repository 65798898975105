<template>
  <section id="about" class="AboutUs">
    <div class="Wrapper">
      <div class="AboutUs-List">
        <div v-for="(info, i) in infos" :key="i" class="AboutUs-Item">
          <div class="AboutUs-ItemOdometer">
            <span>{{ info.number }}</span>
            <span class="AboutUs-ItemOdometerText">
            {{ info.sub }}
          </span>
          </div>
          <div class="TextUpper AboutUs-ItemText"
               v-html="info.text"></div>
        </div>
      </div>
      <img loading="lazy" v-show="$screen.width > 959" src="@/assets/images/about-dots.svg" alt="bg" class="AboutUs-BgDots">
      <img loading="lazy" v-show="$screen.width < 959" src="@/assets/images/about-dots-mobile.svg" alt="bg" class="AboutUs-BgDots">
      <div v-show="$screen.width > 959" class="AboutUs-Lighting Lighting1"></div>
      <div v-show="$screen.width > 959" class="AboutUs-Lighting Lighting2"></div>
      <LottieAnimation
          v-show="$screen.width > 959"
          class="AboutUs-SphereImg anim"
          ref="anim"
          :animationData="require('@/assets/animations/circle-animation.json')"
          background="transparent"
          loop
          autoPlay
      />
    </div>
  </section>
</template>

<script>
import LottieAnimation from 'lottie-web-vue';

export default {
  name: "AboutUs",
  components: {
    LottieAnimation,
  },
  data() {
    return {
      number: 0,
      dig: 100,
      infos: [
        {number: 23, sub: "k", text: this.$t('aboutUs.text1')},
        {number: 70, sub: "", text: this.$t('aboutUs.text2')},
        {number: 55, sub: "", text: this.$t('aboutUs.text3')},
        {number: '05', sub: "", text: this.$t('aboutUs.text4')},
      ]
    }
  },
  methods: {},
  mounted() {
    for (let i = 0; i < this.infos.length; i++) {
      if (this.number < this.infos[i].number) {
        this.number += 1;
      }
    }
  },
}
</script>

<style lang="scss">
.AboutUs {
  padding: 0 0 40px;
  scroll-margin-top: 140px;
  @media (min-width: $screen-l) {
    padding: 90px 0 40px;
    scroll-margin-top: 70px;
  }
  @media (min-width: $screen-xl) {
    padding: 179px 0 80px;
  }

  &-List {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px 37px;

    @media (min-width: $screen-l) {
      grid-template-columns: repeat(2, 300px);
      grid-gap: 52px 52px;
      justify-content: center;
    }

    @media (min-width: $screen-xl) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-Item {
    @media (min-width: $screen-xl) {
      width: 218px;
    }
  }

  &-ItemOdometer {
    margin-bottom: 14px;
    font-size: 78px;
    line-height: 1.12;
    color: var(--color-text-main2);

    @media (min-width: $screen-l) {
      margin-bottom: 24px;
      font-size: 120px;
    }
  }

  .iOdometer {
    //display: inline;
  }

  &-ItemOdometerText {
    //position: relative;
    //top: 6px;
    font-size: 20px;
    line-height: 1.12;

    @media (min-width: $screen-l) {
      font-size: 30px;
    }
  }

  &-BgDots {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
    opacity: .5;

    @media (min-width: $screen-xl) {
      top: 30%;
    }

    @media (min-width: $screen-xxl) {
      left: calc(50% + 180px);
    }
  }

  &-Lighting {
    position: absolute;
    z-index: -3;
    transform: translate(0, -50%);
    width: 120px;
    height: 120px;
    background: var(--color-text-main2);
    border-radius: 50%;
    box-shadow: 1px 1px 1000px var(--color-text-main2);
    filter: blur(30px);
    opacity: .2;

  }

  .Lighting1 {
    top: 16%;
    left: 90px;

    box-shadow: 1px 1px 50px var(--color-text-main2);
    filter: blur(60px);
  }

  .Lighting2 {
    top: 57%;
    right: 200px;
    @media (min-width: $screen-xl) {
      top: 15%;
      right: 0;
      box-shadow: 1px 1px 30px var(--color-text-main2);
      filter: blur(60px);
    }
  }

  &-SphereImg {
    width: 230px;
    position: absolute;
    z-index: -1;
    top: 10%;
    left: 220px;
    transform: translate(0, -50%);
    opacity: .3;

    @media (min-width: $screen-xl) {
      left: 60px;
    }
  }
}
</style>